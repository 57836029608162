import React from 'react'
import { graphql } from 'gatsby'
import { Button, Wrapper, Billboard, SEO } from '@renderbus/common/components'
import {
  Section,
  LightSection,
  BannerContainer,
  Software,
  RegisteredContainer,
  RegisteredTitle,
  SubTitle,
  AdvantageItem,
  WebsiteCard,
  AdvantageContainer,
  IntroWrapper,
  BannerContent,
  ChoiceContainer,
  IntroduceContent,
  SectionTitle,
  SectionContentTitle,
  SoftwareBG,
  SoftwareItem,
  IntroduceImage,
  SoftwareImageList,
  ServiceList,
} from './collaborative-cloud-production.atom'

import Layout from '../molecules/layout'

import QRCode from '../images/collaborative-cloud-production/qrcode.png'

import service01 from '../images/collaborative-cloud-production/service-01.png'
import service02 from '../images/collaborative-cloud-production/service-02.png'

import zz01 from '../images/rendering-cluster/zz01.png'
import zz02 from '../images/rendering-cluster/zz02.png'
import zz04 from '../images/rendering-cluster/zz04.png'
import zz05 from '../images/rendering-cluster/zz05.png'
import zz06 from '../images/rendering-cluster/zz06.png'

import AdvantageIcon1 from '@renderbus/common/components/svg/advantage-01'
import AdvantageIcon2 from '@renderbus/common/components/svg/advantage-02'
import AdvantageIcon3 from '@renderbus/common/components/svg/advantage-03'
import AdvantageIcon4 from '@renderbus/common/components/svg/advantage-04'
import AdvantageIcon5 from '@renderbus/common/components/svg/advantage-05'

import ProgramIcon1 from '@renderbus/common/components/svg/program-01'
import ProgramIcon2 from '@renderbus/common/components/svg/program-02'
import ProgramIcon3 from '@renderbus/common/components/svg/program-03'
import ProgramIcon4 from '@renderbus/common/components/svg/program-04'
import ProgramIcon5 from '@renderbus/common/components/svg/program-05'

import MayaIcon from '../images/index/icons/maya.png'
import MaxIcon from '../images/index/icons/max.png'
import C4DIcon from '../images/index/icons/c4d.png'
import BlenderIcon from '../images/index/icons/blender.png'
import VrayIcon from '../images/index/icons/v-ray.png'
import MoreIcon from '../images/index/icons/more.png'

const SoftwareList = [
  {
    name: 'Maya',
    icon: MayaIcon,
    alt: 'Maya云渲染',
    width: 40,
  },
  {
    name: '3ds Max',
    icon: MaxIcon,
    alt: 'Max云渲染',
    width: 40,
  },
  {
    name: 'Cinema 4d',
    icon: C4DIcon,
    alt: 'Cinema 4d 云渲染',
    width: 42,
  },
  {
    name: 'Blender',
    icon: BlenderIcon,
    alt: 'Blender 云渲染',
    width: 56,
  },
  {
    name: 'V-Ray',
    icon: VrayIcon,
    alt: 'Vray 云渲染',
    width: 40,
  },
]

const choiceList = [
  {
    img: '',
    title: '支持多种软件和插件',
    imgwidth: 602,
    description: '云渲染管理系统支持大部分主流3D制作软件和插件，也可上传自定义插件。',
  },
  {
    img: <ProgramIcon1 />,
    title: '强大的资源管理能力',
    imgwidth: 542,
    description:
      '云渲染管理系统提供作业管理、节点管理、调度管理、存储管理、软件管理等一整套资源管理能力，使用者无需关注底层架构搭建、数据传输、网络延迟、运维服务等问题，助力制作者无忧渲染，全心创作。',
  },
  {
    img: <ProgramIcon2 />,
    title: '数据安全性高',
    imgwidth: 519,
    description: '系统整体服务都以私有化方式部署，无需公网上传、下载，可局域网内做账户权限管控。',
  },
  {
    img: <ProgramIcon3 />,
    title: '部署效率快',
    imgwidth: 502,
    description: '渲染平台、节点服务支持一键安装部署，新增一个节点只需3分钟即可搞定。',
  },
  {
    img: <ProgramIcon4 />,
    title: '支持多客户端服务',
    imgwidth: 562,
    description:
      '提供多账户系统，服务器可以支持10000+的客户端同时在线，支持单个集群10000+以上节点机调度，足够支撑园区100-140家公司同时上线访问与算力增长需求。',
  },
  {
    img: <ProgramIcon5 />,
    title: '一机多用降成本',
    imgwidth: 541,
    description: '服务机、节点机、制作机，可灵活切换使用，闲时可做节点，忙时可做客户端。',
  },
]

const websiteList = [
  {
    img: service02,
    title: '渲染专家团队',
    description:
      '我们的专家团队拥有好莱坞多年的CGI电影制作管理经验，作为全球数万工作室采用的渲染服务提供商，一年内平台处理的影视项目数以百计。我们的TD团队拥有顶尖CG制作公司的从业经验，在CG渲染优化、高性能计算方面投入了大量的研究资源。',
  },
  {
    img: service01,
    title: '研发团队',
    description:
      '瑞云拥有超过300+人的研发团队，我们在高性能集群系统、海量存储以及高吞吐量网络IO方面，都有深入的研究。我们的目标是通过背后复杂的技术，为全球CG行业用户提供一个简单易用的渲染平台。',
  },
]

const qualityList = [
  {
    img: zz01,
    title1: '知识产权管理',
    title2: '体系认证',
  },
  {
    img: zz02,
    title1: '国家高新',
    title2: '企业认证',
  },
  {
    img: zz04,
    title1: '中国云渲染平台',
    title2: '领军企业奖',
  },
  {
    img: zz05,
    title1: 'ISO27001',
    title2: '安全认证',
  },
  {
    img: zz06,
    title1: '奥斯卡获奖',
    title2: '作品',
  },
]

const advantageList = [
  {
    img: <AdvantageIcon1 />,
    title: '独立网格',
  },
  {
    img: <AdvantageIcon2 />,
    title: '独立渲染机节点',
  },
  {
    img: <AdvantageIcon3 />,
    title: '定制渲染环境',
  },
  {
    img: <AdvantageIcon4 />,
    title: '用户插件部署',
  },
  {
    img: <AdvantageIcon5 />,
    title: '独立的服务团队',
  },
]

class CollaborativeCloudProduction extends React.PureComponent {
  state = {
    isQrCodeShow: false,
    isQrCodeShow1: false,
  }
  handleQrcodeShow = () => {
    this.setState({
      isQrCodeShow1: !this.state.isQrCodeShow1,
    })
  }
  handleQrcodeShowHeader = e => {
    this.setState({
      isQrCodeShow: !this.state.isQrCodeShow,
    })
  }
  handleCloseQrCode = e => {
    if (e.target.id) return
    this.setState({
      isQrCodeShow: false,
      isQrCodeShow1: false,
    })
  }
  render() {
    const {
      location,
      data,
      pageContext: { topThreeShare },
    } = this.props
    return (
      <Layout location={location} topThreeShare={topThreeShare}>
        <SEO
          title='Renderbus云渲染管理系统 - 三步操作实现渲染集群管理私有化部署'
          keywords='渲染集群管理软件,集群渲染解决方案,渲染集群搭建,渲染服务器集群搭建'
          description='Renderbus云渲染管理系统为瑞云渲染自主研发的渲染集群管理软件,集群渲染解决方案,渲染服务器集群搭建操作简单,几步操作即可实现渲染集群服务本地私有化部署。'
        />
        <BannerContainer onClick={this.handleCloseQrCode}>
          <BannerContent>
            <h1>Renderbus云渲染管理系统</h1>
            <h2>三步操作实现私有化部署，一键尊享专属渲染集群服务</h2>
            <Button variant='outlined' onClick={this.handleQrcodeShowHeader} id='lxwm-yxrglxt'>
              联系我们
            </Button>
            {this.state.isQrCodeShow && (
              <div className='qrcode-wrap'>
                <div className='close-icon' onClick={this.handleQrcodeShowHeader} />
                <img src={QRCode} alt='联系二维码' />
                <p>扫描二维码，随时聊一聊</p>
                <p>联系人：冯先生</p>
                <p>服务热线：18612698101</p>
              </div>
            )}
          </BannerContent>
          <Billboard
            style={{ top: '60px' }}
            fluid={[
              data.bannerMb.childImageSharp.fluid,
              {
                ...data.banner.childImageSharp.fluid,
                media: `(min-width: 780px)`,
              },
            ]}
          />
        </BannerContainer>
        <Section>
          <Wrapper>
            <IntroWrapper>
              <p>
                Renderbus云渲染管理系统是一款云渲染集群管理系统，以私有化部署方式提供渲染集群服务，您只需一键安装部署服务器端Renderbus
                Server和客户端Renderbus Client，就可以快速、高效地搭建起私有化渲染系统。
              </p>
            </IntroWrapper>
          </Wrapper>
        </Section>
        <LightSection>
          <Wrapper>
            <SectionTitle marginBottom={'75px'} paddingTop={'43px'} marginMbBottom={'45px'}>
              为什么选择Renderbus云渲染管理系统
            </SectionTitle>
            {choiceList.map((p, i) => (
              <ChoiceContainer key={p.title} direction={i}>
                <IntroduceContent>
                  <SectionContentTitle>{p.title}</SectionContentTitle>
                  <SubTitle>{p.description}</SubTitle>
                </IntroduceContent>
                <IntroduceImage svgWidth={p.imgwidth}>
                  {i ? (
                    p.img
                  ) : (
                    <>
                      <div style={{ display: 'flex' }}>
                        <SoftwareImageList>
                          {SoftwareList.map((soft, index) => (
                            <SoftwareItem direction='column' key={index} isShow={true}>
                              <SoftwareBG imgWidth={soft.width}>
                                <img src={soft.icon} alt={soft.alt || soft.name} />
                              </SoftwareBG>
                              <p>{soft.name}</p>
                            </SoftwareItem>
                          ))}
                          <SoftwareItem direction='column' isShow={true}>
                            <SoftwareBG imgWidth={30}>
                              <img src={MoreIcon} alt='更多' />
                            </SoftwareBG>
                            <p>更多</p>
                          </SoftwareItem>
                        </SoftwareImageList>
                      </div>
                    </>
                  )}
                </IntroduceImage>
              </ChoiceContainer>
            ))}
          </Wrapper>
        </LightSection>
        <Section>
          <Wrapper>
            <SectionTitle marginBottom={'77px'} marginTop={'20px'} mbPaddingRight={'24px'}>
              选择Renderbus云渲染管理系统，您将拥有如下定制化服务
            </SectionTitle>
            <AdvantageContainer>
              {advantageList.map((p, i) => (
                <AdvantageItem key={i}>
                  {p.img}
                  <p>{p.title}</p>
                </AdvantageItem>
              ))}
            </AdvantageContainer>
            <ServiceList>
              {websiteList.map((p, i) => (
                <WebsiteCard key={i}>
                  <img src={p.img} alt={p.title} />
                  <div className='picDescription'>{p.title}</div>
                  <div className='desc'>{p.description}</div>
                </WebsiteCard>
              ))}
            </ServiceList>
          </Wrapper>
        </Section>
        <Section style={{ backgroundColor: '#181818' }} paddingMbBottom={'0'}>
          <Wrapper>
            <SectionTitle paddingTop={'36px'} marginBottom={'0'}>
              我们的资质
            </SectionTitle>
            <div className='quality-list'>
              {qualityList.map((p, i) => (
                <Software key={i}>
                  <img src={p.img} alt={p.title1} />
                  <p>{p.title1}</p>
                  <p>{p.title2}</p>
                </Software>
              ))}
            </div>
          </Wrapper>
        </Section>
        <RegisteredContainer onClick={this.handleCloseQrCode}>
          <Wrapper>
            <RegisteredTitle>
              想要更多了解Renderbus云渲染管理系统以及获得云渲染私有化部署方案
            </RegisteredTitle>
            <Button variant='outlined' onClick={this.handleQrcodeShow} id='lxwm-yxrglxt'>
              联系我们
            </Button>
            {this.state.isQrCodeShow1 && (
              <div className='qrcode-wrap'>
                <div className='close-icon' onClick={this.handleQrcodeShow} />
                <img src={QRCode} alt='联系二维码' />
                <p>扫描二维码，随时聊一聊</p>
                <p>联系人：冯先生</p>
                <p>服务热线：18612698101</p>
              </div>
            )}
          </Wrapper>
        </RegisteredContainer>
      </Layout>
    )
  }
}

export default CollaborativeCloudProduction

export const query = graphql`
  query {
    banner: file(relativePath: { regex: "/collaborative-cloud-production/banner.png/" }) {
      ...fluidImage
    }
    bannerMb: file(relativePath: { regex: "/collaborative-cloud-production/banner-mb.png/" }) {
      ...fluidImage
    }
  }
`
